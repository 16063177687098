import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSearch,
  faArrowRight,
  faAngleDown,
  faBowlFood,
  faFilter,
  faDollar
} from '@fortawesome/free-solid-svg-icons'
import bgimage from '../../Images/Hotels/bg.jpg'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite'
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import Layout from '../../Components/Layout/Layout'
import moment from 'moment'
import Axios from 'axios'
import { CurrencyConverter } from '../../Components/GlobalData/GlobalData'
function Hotels () {
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)

  var hotels1 = useSelector(state => state.hotels.hotels)
  const [hotels, sethotels] = useState(hotels1)
  var hotelsSearchindex = useSelector(state => state.hotels.hsearch)
  var minamounts2 = hotels.hotels_list.map(hotel => Number(hotel.min_price))
  var minValue2 = Math.min(...minamounts2)
  var maxValue2 = Math.max(...minamounts2)
  // var minValue3=calculateMarkup(Math.min(...minamounts2),hotels.hotels_list[0]?.admin_markup,hotels.hotels_list[0]?.admin_markupadmin_markup_type,hotels.hotels_list[0]?.customer_markup,hotels.hotels_list[0]?.customer_markup_type);
  // var maxValue3=calculateMarkup(Math.max(...minamounts2),hotels.hotels_list[0]?.admin_markup,hotels.hotels_list[0]?.admin_markupadmin_markup_type,hotels.hotels_list[0]?.customer_markup,hotels.hotels_list[0]?.customer_markup_type);
  const [showPriceNew, setShowPriceNew] = useState(true)
  const [minValue, setMinValue] = useState(minValue2)
  const [maxValue, setMaxValue] = useState(maxValue2)
  const [hotelsSearchData, sethotelsSearchData] = useState(hotelsSearchindex)
  const [hotelNameFilter, setHotelNameFilter] = useState(null)
  const [baseCName, setBaseCName] = useState('GBP')
  const [rangeValue, setRangeValue] = useState([minValue2, maxValue2])
  const [ShowPriceFilter, setShowPriceFilter] = useState(false)
  const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false)
  const [ShowRatingFilter, setShowRatingFilter] = useState(false)
  const [ShowFacilityFilter, setShowFacilityFilter] = useState(false)
  const [showModifySearch, setShowModifySearch] = useState(false)
  const [baseCurrencyNew, setBaseCurrencyNew] = useState([])
  const [hotelid, setHotelid] = useState('')
  const [showfilter, setShowfilter] = useState({
    rating: false,
    meal: false,
    facility: false
  })
  const [starRating, setStarRating] = useState({
    rating5: '0',
    rating4: '0',
    rating3: '0',
    rating2: '0',
    rating1: '0',
    type: 'Stars'
  })
  const [mealRating, setMealRating] = useState({
    meal1: '',
    meal2: '',
    meal3: '',
    meal4: '',
    type: 'meal'
  })
  const [selectedFacilities, setSelectedFacilities] = useState({
    type: 'facility'
  }) // Initialize an empty array for selected items

  useEffect(() => {
    var curency = hotels.hotels_list[0]?.hotel_curreny
    AllCurrency(curency)
  }, [])

  const renderPrice3 = price => {
    if (showPriceNew === false) {
      var baseprice = Number(price)
    } else if (GBPCurrencyRates === undefined) {
      var baseprice = Number(price)
    } else if (CurrencyRates === undefined) {
      const gbpprice = baseCurrencyNew[baseCName] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrencyNew[baseCName]
      var baseprice1 = Number(gbpprice) * Number(price)
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = Number(gbpprice2) * Number(baseprice1)
    }
    return Number(baseprice).toFixed(0)
  }

  const AllCurrency = curency => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + curency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data
        setBaseCurrencyNew(response.data.conversion_rates)
        localStorage.setItem('HotelCurrency', JSON.stringify(response.data))
      })
      .catch(error => {
        // Handle errors here
        localStorage.setItem('HotelCurrency', null)
        setShowPriceNew(false)
        console.error(error)
      })
  }
  const calculateMarkup = price => {
    if (hotels1.length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hotels1.hotels_list[0].admin_markup) !== 0) {
        if (hotels1.hotels_list[0].admin_markup_type === 'Percentage') {
          markupprice =
            (price * Number(hotels1.hotels_list[0].admin_markup)) / 100
        } else {
          markupprice = Number(hotels1.hotels_list[0].admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hotels1.hotels_list[0].customer_markup) !== 0) {
        if (hotels1.hotels_list[0].customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup *
              Number(hotels1.hotels_list[0].customer_markup)) /
            100
        } else {
          markupprice = Number(hotels1.hotels_list[0].customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      return finalpricemarkup.toFixed(2)
    }
  }

  const handleCheckboxChange = event => {
    const { name, value } = event.target
    setStarRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '0') {
        updatedStarRating[name] = '0'
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }

  useEffect(() => {
    sethotelsSearchData(hotelsSearchindex)
    sethotels(hotels1)
    // hotels=hotels;
  }, [hotelsSearchindex, hotels1])
  const handleMealTypeChange = event => {
    const { name, value } = event.target

    setMealRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '') {
        updatedStarRating[name] = ''
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }

  const handleFacilityChange = event => {
    const { name, value } = event.target
    const isSelected = selectedFacilities[name] === value

    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities }
      delete updatedFacilities[name]
      setSelectedFacilities(updatedFacilities)
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value })
    }
  }
  useEffect(() => {}, [hotelsSearchData, hotels1])
  const sliderRef = useRef(null)

  useEffect(() => {
    const slider = sliderRef.current

    // Check if the slider element exists
    if (slider) {
      noUiSlider.create(slider, {
        start: [minValue, maxValue],
        connect: true,
        range: {
          min: minValue2,
          max: maxValue2
        }
      })

      slider.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          setMinValue(parseInt(values[handle]))
        } else {
          setMaxValue(parseInt(values[handle]))
        }
      })

      return () => slider.noUiSlider.destroy()
    }

    // If the slider element doesn't exist, do nothing
  }, [minValue, maxValue, minValue2, maxValue2])
  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value)
    setHotelNameFilter(inputValue)
  }
  const StarFilter = () => {
    setShowRatingFilter(false)
    setHotelid(starRating)
  }
  const PriceFilter = () => {
    setShowPriceFilter(false)
    var data = { type: 'price', min: minValue, max: maxValue }
    setHotelid(data)
  }
  const MealFilter = () => {
    setShowMealTYpeFilter(false)
    setHotelid(mealRating)
  }

  const FacilityFilter = () => {
    setShowFacilityFilter(false)
    setHotelid(selectedFacilities)
  }
  const DisplayModifySearch = () => {
    setShowModifySearch(!showModifySearch)
  }
  const Showfilter = num => {
    if (num === 1) {
      setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }))
    }
    if (num === 2) {
      setShowfilter(prevData => ({ ...prevData, meal: !prevData.meal }))
    }
    if (num === 3) {
      setShowfilter(prevData => ({ ...prevData, facility: !prevData.facility }))
    }
  }

  const TogglePriceFilter = () => {
    setShowPriceFilter(!ShowPriceFilter)
  }
  const ToggleMealTypeFilter = () => {
    setShowMealTYpeFilter(!ShowMealTYpeFilter)
  }
  const ToggleRatingFilter = () => {
    setShowRatingFilter(!ShowRatingFilter)
  }
  const ToggleFacilityFilter = () => {
    setShowFacilityFilter(!ShowFacilityFilter)
  }
  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
    setMinValue(newRangeValue[0])
    setMaxValue(newRangeValue[1])
  }
  return (
    <>
      <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
        <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
        <ModalBody>
          <div className='widget widget_price_filter'>
            <div className='mb-0'>
              {/* <h3 className='form-label'>Price Level</h3> */}
              <RangeSlider
                value={rangeValue}
                onChange={handleChange}
                min={minValue2}
                tooltip={false}
                max={maxValue2}
                step={1}
              />{' '}
              <div className='pt-2'>
                <div className='fw-bold mb-2'>
                  Min:{' '}
                  <span id='kt_slider_basic_min'>
                    {renderPrice3(calculateMarkup(minValue))}{' '}
                  </span>
                </div>
                <div className='fw-bold mb-2'>
                  Max:{' '}
                  <span id='kt_slider_basic_max'>
                    {renderPrice3(calculateMarkup(maxValue))}
                  </span>
                </div>
              </div>
              <button onClick={PriceFilter} className='btn btn-warning m-2'>
                Apply
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ShowMealTYpeFilter} toggle={ToggleMealTypeFilter}>
        <ModalHeader toggle={ToggleMealTypeFilter}>
          Meal Type Filter
        </ModalHeader>
        <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
            <div className='filter-show-hide'>
              {/* <h3>Meal Type</h3> */}
              {/* <FontAwesomeIcon icon={faAngleDown}/> */}
            </div>
            <div>
              <ul>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleMealTypeChange}
                      name='meal1'
                      value='ROOM ONLY'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Room Only</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleMealTypeChange}
                      name='meal2'
                      value='BED AND BREAKFAST'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> BED AND BREAKFAST </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleMealTypeChange}
                      name='meal3'
                      value='HALF BOARD'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> HALF BOARD</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleMealTypeChange}
                      name='meal4'
                      value='Full BOARD'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Full BOARD</span>
                  </label>
                </li>
              </ul>
              <button onClick={MealFilter} className='btn btn-warning m-2'>
                Apply
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
        <ModalHeader toggle={ToggleRatingFilter}>
          Star Rating Filter
        </ModalHeader>
        <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
            <div className='filter-show-hide' onClick={() => Showfilter(1)}>
              {/* <h3>Filter by Rating</h3> */}
              {/* <FontAwesomeIcon icon={faAngleDown}/> */}
            </div>
            <div>
              <ul>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating5'
                      value='5'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating4'
                      value='4'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating3'
                      value='3'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating2'
                      value='2'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating1'
                      value='1'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
              </ul>
              <button onClick={StarFilter} className='btn btn-warning m-2'>
                Apply
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ShowFacilityFilter} toggle={ToggleFacilityFilter}>
        <ModalHeader toggle={ToggleFacilityFilter}>Facility Filter</ModalHeader>
        <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
            <div className='filter-show-hide' onClick={() => Showfilter(3)}>
              {/* <h3>Facilities</h3> */}
              {/* <FontAwesomeIcon icon={faAngleDown}/> */}
            </div>
            <div>
              <ul>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility1'
                      value='Wi-fi'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Wi-fi</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility2'
                      value='Internet access'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Internet access </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility3'
                      value='TV'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> TV</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility4'
                      value='Wake-up service'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Wake-up service</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility4'
                      value='Smoking rooms'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Smoking rooms</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility6'
                      value='Wheelchair-accessible'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Wheelchair-accessible</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility7'
                      value='Laundry service'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Laundry service</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility8'
                      value='Banquet hall'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Banquet hall</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility9'
                      value='Non-smoking establishment'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Non-smoking establishment</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleFacilityChange}
                      name='facility10'
                      value='Safe'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'> Safe</span>
                  </label>
                </li>
              </ul>
              <div>
                <button
                  onClick={FacilityFilter}
                  className='btn btn-warning m-2'
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Layout>
        <div className='container-fluid'>
          <div className='mt-3 px-3'>
            <HotelSearch />
          </div>
        </div>
        <div className='m-4'>
          <div className='row'>
            <div className='col-md-3 col-md-pull-9'>
              <div>
                <div class='mobile-Filter-info mb-3'>
                  <ul>
                    <li>
                      <p onClick={TogglePriceFilter}>
                        <FontAwesomeIcon icon={faDollar} /> Price
                      </p>
                    </li>
                    <li>
                      <p onClick={ToggleMealTypeFilter}>
                        <FontAwesomeIcon icon={faBowlFood} />
                        <span> Meal Type</span>
                      </p>
                    </li>
                    <li>
                      <p onClick={ToggleRatingFilter}>
                        <FontAwesomeIcon icon={faStar} />
                        <span> Rating</span>
                      </p>
                    </li>
                    <li>
                      <p onClick={ToggleFacilityFilter}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span> Facilities</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className='page-sidebar'>
                  <div
                    id='map-container-google-2'
                    className='z-depth-1-half map-container'
                    style={{ height: '500' }}
                  >
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                    &q=${hotelsSearchData.destination_name}`}
                      frameborder='0'
                      allowfullscreen
                      className='rounded-4'
                    ></iframe>
                  </div>
                  {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div class="uitk-layout-flex uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-primary-theme">
                    <div class="uitk-switch uitk-layout-flex-item uitk-switch-theme-standard uitk-switch-position-trailing">
                      <input type="checkbox" name="compare-toggle-filter" id="compare-toggle-filter" class="uitk-switch-input" aria-label="Compare properties" />
                      <div aria-hidden="true" class="uitk-switch-control">
                        <div class="uitk-switch-control-handle">
                          <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="uitk-switch-content mr-3">
                        <label class="uitk-switch-label" for="compare-toggle-filter">
                          <span class="uitk-switch-label-content">Compare properties</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <hr style={{ opacity: '1' }} />
                  <div className='widget widget_price_filter'>
                    <h3 className='form-label'>Search by property name</h3>
                    <Select
                      value={hotelNameFilter}
                      onChange={handleHotelFilter}
                      options={hotels.hotels_list.map(option => ({
                        value: option.hotel_id,
                        label: option.hotel_name
                      }))}
                    />
                  </div>
                  <hr style={{ opacity: '1' }} />
                </div>
                <div className='page-sidebar hide-page_filter '>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div>
                      <fieldset data-testid='price'>
                        <legend class='uitk-spacing uitk-spacing-margin-blockend-two'>
                          <h4 class='uitk-heading uitk-heading-7 mb-2'>
                            Price per night
                          </h4>
                        </legend>
                        <div class='slider-text-input--container'>
                          <div class='slider-text-input--input_container'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-content-space-between'>
                              <div class='uitk-field'>
                                <label
                                  for='price-min'
                                  class='uitk-field-label is-visually-hidden'
                                >
                                  Min
                                </label>
                                <input
                                  type='text'
                                  value={renderPrice3(
                                    calculateMarkup(minValue)
                                  )}
                                  class='uitk-field-input uitk-layout-grid-item remove-spinner'
                                  id='kt_slider_basic_min'
                                  step='1'
                                  style={{ sliderPrimary: '0%' }}
                                />
                                <div
                                  class='uitk-field-label'
                                  aria-hidden='true'
                                >
                                  Min
                                </div>
                              </div>
                              <div class='uitk-field'>
                                <label
                                  for='price-max'
                                  class='uitk-field-label is-visually-hidden'
                                >
                                  Max
                                </label>
                                <input
                                  type='text'
                                  value={renderPrice3(
                                    calculateMarkup(maxValue)
                                  )}
                                  class='uitk-field-input uitk-layout-grid-item remove-spinner'
                                  id='kt_slider_basic_max'
                                  step='1'
                                  style={{ sliderPrimary: '0%' }}
                                />
                                <div
                                  class='uitk-field-label'
                                  aria-hidden='true'
                                >
                                  Max
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='slider-text-input--slider my-4'>
                            <div
                              data-testid='price-slider-container'
                              class='price-slider-container'
                            >
                              <RangeSlider
                                value={rangeValue}
                                onChange={handleChange}
                                min={minValue2}
                                tooltip={false}
                                max={maxValue2}
                                step={1}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              className='btn btn-primary btn-block select-styling search-btn1'
                              onClick={PriceFilter}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className='widget widget_has_radio_checkbox'>
                    <div className='filter-show-hide bwCBvh kuQtDu mt-3'>
                      <h3 className='fMYYEI'>Filter by Rating</h3>
                      {/* <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i> */}
                    </div>
                    <div className='edRKIj gCLia-d'>
                      <ul className='bzqoba '>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleCheckboxChange}
                              name='rating5'
                              value='5'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleCheckboxChange}
                              name='rating4'
                              value='4'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleCheckboxChange}
                              name='rating3'
                              value='3'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleCheckboxChange}
                              name='rating2'
                              value='2'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleCheckboxChange}
                              name='rating1'
                              value='1'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={StarFilter}
                        className='btn btn-primary btn-block select-styling search-btn1'
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div
                    className='widget widget_has_radio_checkbox'
                    style={{ borderBottom: '0' }}
                  >
                    <div className='filter-show-hide'>
                      <h3>Meal Type</h3>
                      {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                    </div>
                    <div className='mb-2'>
                      <ul>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleMealTypeChange}
                              name='meal1'
                              value='ROOM ONLY'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Room Only</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleMealTypeChange}
                              name='meal2'
                              value='BED AND BREAKFAST'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> BED AND BREAKFAST </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleMealTypeChange}
                              name='meal3'
                              value='HALF BOARD'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> HALF BOARD</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleMealTypeChange}
                              name='meal4'
                              value='Full BOARD'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Full BOARD</span>
                          </label>
                        </li>
                      </ul>
                      <button
                        className='btn btn-primary btn-block select-styling search-btn1'
                        onClick={MealFilter}
                      >
                        Apply
                      </button>
                      {/* <button style={{width:'96%'}} onClick={MealFilter} className='btn btn-warning m-2'>Apply</button> */}
                    </div>
                  </div>
                  <div
                    className='widget widget_has_radio_checkbox'
                    style={{ borderBottom: '0' }}
                  >
                    {/* <div className='filter-show-hide' onClick={()=>Showfilter(3)}>                
                  <FontAwesomeIcon icon={faAngleDown}/>
                  </div> */}
                    <div className='my-3'>
                      <h3>Facilities</h3>
                      <ul>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility1'
                              value='Wi-fi'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Wi-fi</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility2'
                              value='Internet access'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Internet access </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility3'
                              value='TV'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> TV</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility4'
                              value='Wake-up service'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Wake-up service</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility4'
                              value='Smoking rooms'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Smoking rooms</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility6'
                              value='Wheelchair-accessible'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              {' '}
                              Wheelchair-accessible
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility7'
                              value='Laundry service'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Laundry service</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility8'
                              value='Banquet hall'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Banquet hall</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility9'
                              value='Non-smoking establishment'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              {' '}
                              Non-smoking establishment
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={handleFacilityChange}
                              name='facility10'
                              value='Safe'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Safe</span>
                          </label>
                        </li>
                      </ul>
                      <button
                        className='btn btn-primary btn-block select-styling search-btn1'
                        onClick={FacilityFilter}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-9 col-md-push-3 mt-3'>
              {/* <div  className="d-flex align-items-center w-100 justify-content-between">
              <div className=' ms-2'>
                <h5 className='title font-size-24 tc' id='tours_result'>
                  {hotelsSearchData.destination_name} - {hotelsSearchData.country}
                </h5>
                <h6 className='title font-size-24 tc' id='tours_result'>
                {hotelsSearchData.adult !== 0 && (
                  <>
                    {hotelsSearchData.adult} adult {' '}
                  </>
                )}
                {hotelsSearchData.child !== 0 && (
                  <>
                    - {hotelsSearchData.child} child
                  </>
                )}
                
                </h6>
              </div>
                <h6 className='title font-size-24 tc' id='tours_result'>
                      {moment(hotelsSearchData.check_in).format('MMM Do ')}{' '}
                      <FontAwesomeIcon icon={faArrowRight} />{' '}
                      {moment(hotelsSearchData.check_out).format('MMM Do ')}      
                    </h6>
            </div> */}

              <HotelCard
                baseCurrencyNew={baseCurrencyNew}
                hotelid={hotelid}
                hotelDataNew={hotels}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Hotels
